<template>
  <div class="main">
    <img src="/img/static/logo-landing.svg" />
    <h1>TERMS OF SERVICE</h1>
    <h4>Last Updated: March 22st, 2021</h4>
    <p>
      This agreement between SecurCom Inc., a foreign company registered to
      transact business in the State of Florida (“securCom”), and you (“You”,
      “Your” or “Customer”) consists of (a) these Terms of Service, (b) the
      Additional Terms (as defined below) and (c) any Order Form (as defined
      below), if applicable (collectively, this “Agreement”). This Agreement
      governs Your use of the securCom Services (as defined below).
    </p>
    <p>
      BY EXECUTING AN ORDER FORM, CREATING AN ACCOUNT (AS DEFINED BELOW), USING
      SECURCOM SERVICES OR OTHERWISE INDICATING YOUR ACCEPTANCE OF THIS
      AGREEMENT, YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT YOU HAVE READ THIS
      AGREEMENT AND ARE BOUND BY THIS AGREEMENT, AND YOU HEREBY AGREE TO THE
      TERMS OF THIS AGREEMENT. IF YOU ARE AN INDIVIDUAL ENTERING INTO THIS
      AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU HEREBY
      REPRESENT AND WARRANT TO SECURCOM THAT YOU ARE AUTHORIZED TO ENTER INTO
      THIS AGREEMENT ON BEHALF OF CUSTOMER AND BIND CUSTOMER TO THE TERMS AND
      CONDITIONS CONTAINED IN THIS AGREEMENT, IN WHICH CASE, THE TERM “CUSTOMER”
      WILL REFER TO SUCH ENTITY. IF YOU DO NOT ACCEPT ALL THE TERMS AND
      CONDITIONS IN THIS AGREEMENT OR ARE NOT AN AUTHORIZED AGENT FOR CUSTOMER,
      DO NOT EXECUTE AN ORDER FORM, CREATE AN ACCOUNT OR OTHERWISE USE THE
      SECURCOM SERVICES.
    </p>
    <p>
      securCom reserves the right, at any time and from time to time, to update,
      revise, supplement, and otherwise modify this Agreement (including the
      Additional Terms) and to impose new or additional rules, policies, terms,
      or conditions on Your use of the securCom Services. securCom will
      communicate changes to this Agreement by posting the new version of the
      Agreement on its website at
      <a href="https://www.securcom.me/legal/terms-conditions.pdf"
        >https://www.securcom.me/legal/terms-conditions.pdf</a
      >
      or as otherwise determined by securCom in its sole discretion, at which
      time such updated Agreement will be immediately effective. Your continued
      use of any securCom Services after such notification of changes to this
      Agreement will constitute Your acceptance of any and all such changes.
      Notwithstanding the foregoing, securCom will notify You of any material,
      detrimental change to this Agreement.
    </p>
    <br />
    <h4>1. DEFINITIONS.</h4>
    <ol type="a">
      <li>
        <strong>“Additional Terms” </strong> Policies, the Data Privacy Addendum
        and any other terms and conditions disclosed to Customer in an Order
        Form, through Customer’s Account or in connection with accessing any
        securCom Services, each of which are hereby incorporated by reference.
      </li>
      <li>
        <strong>“Applicable Law”</strong> means all applicable laws,
        regulations, ordinances, rules, codes and orders of governmental
        authorities having jurisdiction over securCom and Customer.
      </li>
      <li>
        <strong>“Documentation” </strong> means written, published information
        accessible at
        <a href="https://www.securcom.me">https://www.securcom.me</a>, as
        updated from time to time.
      </li>
      <li>
        <strong>“Infringement Claim”</strong> means any third party claim that
        the use by Customer solely of the securCom Services, as used as
        contemplated in this Agreement, infringes any patent, trademark or
        copyright of a third party, or misappropriates a trade secret (but only
        to the extent that the misappropriation is not a result of Customer’s
        actions) under the laws of Slovak Republic.
      </li>
      <li>
        <strong>“Intellectual Property Rights”</strong> means copyrights
        (including, without limitation, the exclusive right to use, reproduce,
        modify, distribute, publicly display and publicly perform the
        copyrighted work), trademark rights (including, without limitation,
        trade names, trademarks, 2 service marks, and trade dress), patent
        rights (including, without limitation, the exclusive right to make, use
        and sell), trade secrets, moral rights, right of publicity, authors’
        rights, contract and licensing rights, goodwill and all other
        intellectual property rights as may exist now and/or hereafter come into
        existence and all renewals and extensions thereof, regardless of whether
        such rights arise under the law of Slovak Republic or any other state,
        country or jurisdiction.
      </li>
      <li>
        <strong>"Login Credentials”</strong> means any user IDs, passwords,
        authentication keys or security credentials that enable Customer’s
        access to and management of the securCom Services.
      </li>
      <li>
        <strong>“Order Form”</strong> means a written ordering document executed
        by an authorized representative of each of securCom and Customer that
        incorporates these Terms of Service by reference. The Order Form will
        specify the securCom Services that Customer is purchasing, payment
        obligations related thereto and the duration of the Service Term.
      </li>
      <li>
        <strong>“securCom Partner”</strong> means a third party reseller or
        distributor authorized by securCom to sell securCom Services.
      </li>
      <li>
        <strong>“securCom Services”</strong> means securCom’s services, a
        current list of which is located at
        <a href="https://www.securcom.me.">https://www.securcom.me.</a>
      </li>
      <li>
        <strong>“securCom Services Descriptions”</strong> means the descriptions
        of the securCom Services located at
        <a href="https://www.securcom.me">https://www.securcom.me</a>.
      </li>
      <li>
        <strong>“Service Term”</strong> means the Initial Service Term (as
        defined below) plus any Renewal Terms (as defined below).
      </li>
      <li>
        <strong>“Support”</strong> means the support services provided by or on
        behalf of securCom for the applicable securCom Services purchased by
        Customer pursuant to an Order Form or through Customer’s Account, which
        are described in the Support Policies.
      </li>
      <li>
        <strong>"Support Policies”</strong> means the statement of support,
        service level agreements and any other support policies for securCom
        Services.
      </li>
      <li>
        <strong>“Third Party Product”</strong> means any non-securCom-branded
        products and services (including hardware) and non-securCom-licensed
        software products.
      </li>
      <li>
        <strong>“Updates”</strong> means any updates, enhancements,
        modifications, improvements, patches and/or upgrades to any securCom
        Services that securCom generally makes available to its customers for no
        additional charge.
      </li>
      <li>
        <strong>“Usage Data”</strong> means any and all information reflecting
        the access or use of the securCom Services by or on behalf of Users,
        including, but not limited to, visit-, session-, or stream-data and any
        statistical or other analysis, information or data based on or derived
        from any of the foregoing.
      </li>
      <li>
        <strong>“User Data”</strong> means all applications, files, data,
        information or other content uploaded to or published, displayed or
        backed up through the securCom Services by Customers, Users or securCom
        (when acting upon Customer’s instructions as part of a securCom
        Service), excluding Usage Data.
      </li>
      <li>
        <strong>“Users”</strong> means any users that access Your Content or
        that use the securCom Services under Customer’s Login Credentials.
      </li>
    </ol>
    <br />
    <h4>2. ACCESS TO SECURCOM SERVICES.</h4>
    <ol type="a">
      <li>
        <strong>ACCESS TO SECURCOM SERVICES.</strong> Customer may access and
        use the securCom Services for which it has registered (via an Order Form
        or through Customer’s Account) solely for its own benefit and only in
        accordance with this Agreement. Customer will provide accurate and
        complete information in its Account and will update its information as
        necessary to keep it current. Customer is solely responsible for the
        security of its and its Users’ Login Credentials. Customer will ensure
        that its Users do not share Login Credentials with others. Customer is 3
        responsible for any use that occurs under its Login Credentials,
        including any activities by Users. If Customer believes an unauthorized
        person has gained access to Login Credentials, Customer will notify
        securCom as soon as possible by contacting Customer Support via chat,
        phone or by email directed at
        <a href="mailto:support@securcom.me">support@securcom.me</a>. Customer
        will ensure that Users comply with all terms and conditions of this
        Agreement and Customer remains responsible and liable for the acts and
        omissions of the Users. If Customer becomes aware of any violation by
        any User, Customer will immediately terminate that User’s access to User
        Data.
      </li>
      <li>
        <strong>SUPPORT.</strong> Subject to the terms and conditions of this
        Agreement, securCom will provide support to Customer for the securCom
        Services in accordance with the then applicable Support Policy. Customer
        acknowledges that securCom is not responsible for technical issues that
        cannot be identified as being primarily caused by the securCom Services.
      </li>
    </ol>
    <br />
    <h4>3. ORDERING.</h4>
    <ol type="a">
      <li>
        <strong>PURCHASES</strong>. Customer may purchase the right to access
        and use the securCom Services by executing an Order Form or registering
        for the applicable securCom Services through Customer’s Account.
        Purchase of the securCom Services includes access to any applicable
        Support during the Service Term.
      </li>
      <li>
        <strong>PURCHASE ORDER.</strong> Customer must issue a purchase order to
        securCom or its reseller, as applicable, within five (5) business days
        from the Effective Date of any securCom Order Form, or securCom shall
        have the option to cancel the Order Form and its terms shall be null and
        void. Any additional or conflicting terms contained in any Customer
        purchase order, proposal or other document shall be deemed to be
        rejected by securCom without need of further notice of objection, even
        if such document is acknowledged or accepted by securCom, and regardless
        of any statement to the contrary which may be contained therein, and
        shall be of no effect or in any way binding upon securCom.
      </li>
      <li>
        <strong> PAYMENT TERMS.</strong> Customer agrees to purchase the right
        to access and use the securCom Services for the prices set forth in the
        Order Form or, if an Order Form is not executed, as set forth in the
        securCom Services Descriptions (“Fees”). If Customer purchases through a
        securCom Partner, all fees and other procurement and delivery terms will
        be agreed between Customer and the applicable securCom Partner. Customer
        will pay all invoices issued by securCom within thirty (30) days of the
        date of the invoice or as otherwise set forth in an applicable Order
        Form. Except as otherwise provided in this Agreement or as otherwise
        provided by Applicable Law, all Fees are non-cancelable and
        non-refundable. Unless otherwise set forth in an Order Form or in the
        securCom Services Descriptions, all monthly or prepaid Fees will be due
        in advance and all Fees based on actual metered usage of a securCom
        Service will be due in arrears. If any payment is more than fifteen (15)
        days late (including if payment is late due to a credit card chargeback
        or insufficient funds), securCom may, without limiting any remedies
        available to securCom: (i) terminate this Agreement and/or any
        applicable Order Form; or (ii) suspend performance of or access to the
        applicable securCom Services, until payment is made current. Customer
        will pay interest on all delinquent amounts at the lesser of 1.5% per
        month or the maximum rate permitted by Applicable Law. All Fees are
        exclusive of all sales, use, excise, value added, withholding and other
        taxes, and all customs duties and tariffs now or hereafter claimed or
        imposed by any governmental authority upon the securCom Services will be
        paid by Customer. securCom reserves the right to increase Fees at any
        time, although increases in Fees for securCom Services will not go into
        effect until the next renewal of the Service Term. EXCEPT AS REQUIRED BY
        APPLICABLE LAW OR AS EXPRESSLY SET FORTH IN THIS AGREEMENT, SECURCOM IS
        NOT OBLIGATED TO REFUND ANY FEES OR OTHER PAYMENTS ALREADY PAID, 4 AND
        ANY CANCELLATION BY CUSTOMER WILL TAKE PLACE AT THE END OF THE
        APPLICABLE SERVICE TERM.
      </li>
      <li>
        <strong>PAYMENT METHOD.</strong> Customer will pay securCom in
        accordance with the payment method identified in an Order Form or as
        established in Customer’s Account, as applicable. Payment method options
        may include payment (i) by credit card, (ii) through an online account
        through a third-party provider, such as PayPal, or (iii) via bank
        transfer. By providing any such credit card or online account
        information, Customer authorizes securCom to automatically charge or
        debit the selected payment method for the full amount due on a recurring
        basis (if applicable) until Customer notifies securCom in writing or
        Customer updates Customer’s Account with an alternative, authorized
        payment method. Customer will provide true, complete and accurate
        information with respect to the applicable method of payment and agrees
        to promptly contact securCom and to otherwise update Customer’s Account
        if any such information needs to be updated. Customer will ensure that
        Customer has sufficient funds or credit (as applicable) associated with
        the selected method of payment. Customer understands that the amounts
        charged or debited may vary and that this authorization will remain in
        effect until the expiration or termination of this Agreement. If an ACH
        payment is returned from the applicable bank account for insufficient or
        uncollected funds or for erroneous information, securCom may reinitiate
        the returned ACH debit to the applicable bank account. Any amounts owed
        to securCom that cannot be collected by ACH debit may be charged to any
        backup credit card on file for Customer.
      </li>
      <li>
        <strong>EU CONSUMER POLICY.</strong> If You are a consumer based in the
        EU, You have the right to cancel this Agreement within fourteen (14)
        calendar days of the date on which You requested the Services, without
        giving any reason. To exercise Your right to cancel, You must notify
        securCom of Your decision to cancel this Agreement by contacting
        Customer Support by email at
        <a href="mailto:support@securcom.me">support@securcom.me</a>. Attention:
        Legal Department. To meet the fourteen (14) day deadline provided above,
        it is sufficient for You to send Your notification concerning the
        exercise of the right to cancel before the cancellation period has
        expired. If You cancel the Agreement, we will reimburse to You all
        payments received from You without undue delay and not later than
        fourteen (14) days from the day on which we are informed about Your
        decision to cancel the Agreement. We will make the reimbursement using
        the same means of payment as You used for the initial transaction,
        unless You have expressly agreed otherwise; in any event, You will not
        incur any fees as a result of the reimbursement. If You are a consumer
        based in the EU and You have agreed to the Services commencing
        immediately, You will be required to pay a pro-rated amount of the Fees
        applicable to the Services You have requested based on the initial Fees
        You have paid for the Services and the date on which You exercise Your
        statutory right to cancel the Agreement.
      </li>
    </ol>
    <br />
    <h4>4. CONFIDENTIALITY.</h4>
    <p>
      As used in this Agreement, “Confidential Information” means, subject to
      the exceptions set forth in the following sentence, any information or
      data, regardless of whether it is in tangible form, disclosed by either
      securCom or Customer (the “Disclosing Party”) that the Disclosing Party
      has either marked as confidential or proprietary, or has identified in
      writing as confidential or proprietary within thirty (30) days of
      disclosure to the other party (the “Receiving Party”); provided, however,
      that a Disclosing Party’s business plans, strategies, technology, research
      and development, current and prospective customers, billing records, and
      products or services will be deemed Confidential Information of the
      Disclosing Party even if not so marked or identified. securCom’s
      Confidential Information includes, without limitation, the securCom
      Services, any information related thereto and the Login Credentials.
      Information will not be deemed Confidential Information 5 if such
      information: (a) is known to the Receiving Party prior to receipt from the
      Disclosing Party directly or indirectly from a source other than one
      having an obligation of confidentiality to the Disclosing Party; (b)
      becomes known (independently of disclosure by the Disclosing Party) to the
      Receiving Party directly or indirectly from a source other than one having
      an obligation of confidentiality to the Disclosing Party; (c) becomes
      publicly known or otherwise ceases to be secret or confidential, except
      through a breach of this Agreement by the Receiving Party; or (d) is
      developed independently by the Receiving Party without use of any
      Confidential Information of the Disclosing Party. Each party agrees that
      it will use the Confidential Information of the other party solely to
      perform its obligations or exercise its rights under this Agreement.
      Neither securCom nor Customer will disclose, or permit to be disclosed,
      the other party’s Confidential Information directly or indirectly, to any
      third party without the other party’s prior written consent. Both securCom
      and Customer will use commercially reasonable measures to protect the
      confidentiality and value of the other party’s Confidential Information.
      Notwithstanding any provision of this Agreement, either party may disclose
      the other party’s Confidential Information, in whole or in part: (i) to
      its employees, officers, directors, consultants and professional advisers
      (e.g. attorneys, auditors, financial advisors, accountants and other
      professional representatives) who have a need to know and are legally
      bound to keep such Confidential Information confidential by
      confidentiality obligations or, in the case of professional advisors, are
      bound by ethical duties to keep such Confidential Information confidential
      consistent with the terms of this Agreement; and (ii) as required by
      Applicable Law (in which case each party will, if permitted by Applicable
      Law, provide the other with prior written notification thereof, will
      provide such party with the opportunity to contest such disclosure, and
      will use its reasonable efforts to minimize such disclosure to the extent
      permitted by Applicable Law). Both securCom and Customer agree to exercise
      due care in protecting the Confidential Information from unauthorized use
      and disclosure. In the event of actual or threatened breach of the
      provisions of this Section, the non-breaching party will be entitled to
      seek immediate injunctive and other equitable relief, without waiving any
      other rights or remedies available to it. Both securCom and Customer will
      promptly notify the other in writing if it becomes aware of any violations
      of the confidentiality obligations set forth in this Agreement.
    </p>
    <br />
    <strong>5. INTELLECTUAL PROPERTY. </strong>
    <ol type="a">
      <li>
        <strong> OWNERSHIP.</strong> This Agreement contains a limited right to
        access and use the securCom Services during a Service Term, not a
        transfer of title to the securCom Services. All Intellectual Property
        Rights in the securCom Services belong exclusively to securCom and its
        licensors. Customer is granted no licenses of any kind to any
        Intellectual Property Rights other than as expressly granted herein.
        Customer will not do, or cause to be done, any acts or things contesting
        or in any way impairing or tending to impair any portion of the right,
        title and interest of securCom in and to the Intellectual Property
        Rights. Customer will not delete or in any manner alter the copyright,
        trademark, or other proprietary rights notices or markings that appear
        on the securCom Services as delivered to Customer. Except as expressly
        authorized in this Agreement, you will not make any copies or duplicates
        of any securCom Services without the prior written permission of
        securCom. To the extent Customer provides any suggestions, comments or
        other feedback related to the securCom Services to securCom or its
        authorized third party agent(s) (“Feedback”), Customer hereby grants
        securCom a worldwide, non-exclusive, perpetual, irrevocable,
        royalty-free, sublicenseable, transferable license to copy, display,
        distribute, perform, modify and otherwise use such Feedback or subject
        matter thereof in any way and without limitation.
      </li>
      <li>
        <strong>RESTRICTIONS.</strong> Except as otherwise expressly provided
        under this Agreement, Customer will have no right, and Customer
        specifically agrees not to: (i) transfer, assign, sublicense or 6 resell
        the securCom Services to another person or entity, and Customer
        acknowledges that any attempted transfer, assignment, sublicense or
        resale will be void; (ii) make error corrections to, or otherwise modify
        or adapt, the securCom Services or create derivative works based upon
        the securCom Services, or permit third parties to do the same; (iii)
        reverse engineer or decompile, decrypt, disassemble or otherwise reduce
        the securCom Services to human-readable form, except to the extent
        otherwise expressly permitted under Applicable Law notwithstanding this
        restriction; (iv) disclose, provide or otherwise make available trade
        secrets contained within the securCom Services in any form, to any third
        party without the prior written consent of securCom; or (vi) use or
        access the securCom Services (A) to develop any software application or
        similar products and services, (B) to spam or distribute malware, (C) in
        a way that could harm the securCom Services or impair anyone else’s use
        of it, (D) in a way intended to work around the securCom Services’
        technical limitations, recurring fees or usage limits, (E) to violate
        any rights of others, (F) to try to gain unauthorized access to, test
        the vulnerability of, or disrupt the securCom Services or any other
        service, device, data account or network or (G) in any application or
        situation where failure of the securCom Services could lead to the death
        or serious bodily injury of any person or to severe physical or
        environmental damage.
      </li>
    </ol>
    <br />
    <h4>6. DATA SECURITY.</h4>
    <ol type="a">
      <li>
        PRIVACY AND DATA STORAGE. securCom’s privacy and data storage practices
        are described in securCom’s data privacy addendum located at
        <a href="https://www.securcom.me/legal/data-privacy-addendum.pdf"
          >https://www.securcom.me/legal/data-privacy-addendum.pdf</a
        >
        (the “Data Privacy Addendum”), which is hereby incorporated by
        reference.
      </li>
      <li>
        <strong> MONITORING.</strong> securCom has the right to verify Your
        compliance with this Agreement. If securCom contacts Customer to verify
        compliance, Customer will provide information or other materials
        reasonably requested to assist in the verification (For German customers
        only: A Customer from Germany is only obliged to provide information
        necessary for such verification, available to the Customer and
        reasonably requested for such verification). securCom may monitor the
        overall performance and stability of the infrastructure of the securCom
        Services. Customer may not block or interfere with that monitoring. If
        securCom reasonably believes a problem with the securCom Services may be
        attributable to User Data or Customer’s use of the securCom Services,
        Customer will cooperate with securCom to identify the source of and
        resolve that problem.
      </li>
      <li>
        <strong>PROTECTED INFORMATION.</strong> You represent and warrant that
        You will not submit any unencrypted Protected Health Information, or any
        unencrypted personally identifiable information subject to regulatory
        protection under Applicable Law (collectively “Unencrypted Protected
        Information”) to securCom, whether as part of the securCom Services or
        otherwise. Notwithstanding anything to the contrary in this Agreement,
        You recognize and agree that securCom will have no liability whatsoever
        under this Agreement or otherwise for any Unencrypted Protected
        Information or Encrypted Protected Information You provide in violation
        of this Section, and You agree to fully indemnify and hold harmless
        securCom from any third party claims resulting from a violation or
        alleged violation of this Section.
      </li>
    </ol>
    <br />
    <h4>7. USER DATA.</h4>
    <ol type="a">
      <li>
        <strong>USER DATA RIGHTS.</strong> As between Customer and securCom,
        Customer retains all right, title, and interest in the User Data, except
        for the limited license expressly granted by Customer to securCom in
        this Section 7. Customer hereby grants to securCom a royalty-free, fully
        paid 7 up, worldwide, sublicenseable, non-transferable (except as set
        forth in Section 19(j)) right and license to copy, display, distribute,
        modify and otherwise use the User Data, solely as necessary to provide
        the securCom Services to Customer. Customer further acknowledges that
        securCom may collect Usage Data and may aggregate and/or anonymize Usage
        Data to use for statistical purposes and share samples of such
        aggregated and/or anonymized Usage Data with other third parties. (For
        German customers only: If Customer is from Germany, securCom may only
        collect and aggregate anonymized Usage Data to use for statistical
        purposes and share samples of such aggregated and anonymized Usage Data
        with other third parties.)
      </li>
      <li>
        <strong>CUSTOMER REPRESENTATIONS AND OBLIGATIONS.</strong> Customer will
        only use the securCom Services with User Data to which it has full
        right, title or license. Customer represents, warrants and covenants
        that its use of the securCom Services and related backup to and storage
        of User Data complies and will comply with all Applicable Laws,
        including those that relate to data privacy, data security,
        international communication and the exportation of technical, personal
        or sensitive data. Customer will not, and will take commercially
        reasonable steps to ensure that each User does not, post content that:
        (i) may create a risk of harm, loss, physical or mental injury,
        emotional distress, death, disability, disfigurement, or physical or
        mental illness to anyone; (ii) may create a risk of any other loss or
        damage to any person or property; (iii) may constitute or contribute to
        a crime or tort; (iv) contains any information or content that is
        illegal, unlawful, harmful, abusive, pornographic, racially or
        ethnically offensive, defamatory, infringing, invasive of personal
        privacy or publicity rights, harassing, humiliating to other people
        (publicly or otherwise), libelous, threatening, or otherwise
        objectionable; or (v) contains any information or content that you do
        not have a right to make available under any law or under contractual or
        fiduciary relationships. Customer represents and warrants that the User
        Data does not and will not violate any third-party rights, including any
        Intellectual Property Rights, and rights of publicity and privacy. If
        Customer becomes aware that any of the User Data or any User’s access to
        or use of the User Data violates this Agreement, Customer will take
        immediate action to remove the applicable part of User Data or suspend
        the User’s access. Customer will ensure that Customer’s use of the
        securCom Services complies at all times with Customer’s privacy policies
        and all Applicable Laws, including any encryption requirements. Customer
        is solely responsible for User Data. Except as provided in the Data
        Privacy Addendum, Customer is responsible for protecting the security of
        User Data, including any access to User Data that Customer provides to
        its employees, customers or other third parties, and when it is in
        transit to and from the securCom Services. Customer must take and
        maintain commercially reasonable steps regarding the security,
        protection and backup of User Data, which might include the use of
        encryption technology to protect User Data from unauthorized access.
        Customer is responsible for providing any necessary notices to Users and
        for obtaining any legally-required consents from Users concerning their
        use of the securCom Services. Customer is responsible for any losses or
        other consequences arising from Customer’s failure to encrypt or back up
        User Data. Customer will have and maintain appropriate policies and
        procedures for cybersecurity and to ensure compliance with its
        regulatory or legal obligations.
      </li>
      <li>
        <strong>DIGITAL MILLENIUM COPYRIGHT ACT.</strong> securCom respects the
        intellectual property rights of others, and we require our Customers to
        do the same. If you believe your copyright is being infringed by content
        on the securCom network, please submit a notice of infringement via
        email addressed to <a href="abuse@securcom.me">abuse@securcom.me</a>.
        securCom is committed to managing copyright complaints responsibly and
        in compliance with applicable laws. In those circumstances 8 where the
        United States DMCA is applicable, it is our policy to expeditiously
        process all valid notices of alleged copyright infringement. Please note
        that sending a DMCA notice initiates a statutorily-defined legal process
        and we will share your full notice, including your contact information,
        with the securCom Customer at issue. In most instances, securCom does
        not have access to particular content hosted by securCom Customers.
        Thus, upon receipt of a valid DMCA notice, securCom will forward your
        notice to the appropriate Customer, and require a timely and adequate
        response from the Customer. You may also send your DMCA notice directly
        to the securCom Customer as well. securCom has adopted and reasonably
        implemented a policy that provides for the termination in appropriate
        circumstances of Customers who are repeat infringers.
      </li>
    </ol>
    <br />
    <h4>8. THIRD PARTY PRODUCTS.</h4>
    <p>
      Customer is responsible for any Third Party Products that a third party
      licenses, sells or makes available to Customer that Customer installs or
      uses with the securCom Services. Customer’s use of such Third Party
      Products is governed by separate terms between Customer and that third
      party. securCom is not a party to and is not bound by any of those
      separate terms. Certain Third Party Products may carry a limited warranty
      from the third-party provider of such Third Party Products. To the extent
      required or allowed, if securCom makes available to Customer a Third Party
      Product in connection with Customer’s use of the securCom Services,
      securCom will pass through to Customer any such manufacturer warranties
      related to such Third Party Products. Notwithstanding the foregoing,
      Customer acknowledges that securCom is not responsible for the
      availability or for the fulfillment of any Third Party Product warranty or
      for problems attributable to use of Third Party Products. ALL THIRD PARTY
      PRODUCTS ARE PROVIDED BY securCom ON AN "AS IS" BASIS. You are responsible
      for reviewing, accepting, and complying with any third party terms of use
      or other restrictions applicable to the Third Party Product. securCom
      reserves the right to suspend or terminate any Third Party Products at any
      time.
    </p>
    <h4>9. TERM AND TERMINATION.</h4>
    <ol type="a">
      <li>
        <strong>TERM.</strong> This Agreement will be effective upon Customer’s
        execution of the Order Form or when Customer otherwise clicks a box
        agreeing to this Agreement (during Account creation or otherwise) and,
        unless earlier terminated as set forth in this Agreement, continue in
        effect for the initial service term identified on the Order Form or
        selected in the Account (the “Initial Service Term”). Unless otherwise
        set forth in an Order Form, this Agreement will automatically renew for
        additional periods of equal duration (each, a “Renewal Term”), unless
        either party gives notice of non-renewal at least thirty (30) days’
        prior to the end of the then-current term (or less if such Service Term
        is monthly).
      </li>
      <li>
        <strong>TERMINATION.</strong> Either party may terminate this Agreement
        or any securCom Services as set forth in the securCom Services
        Description or in an Order Form. In addition to the foregoing
        termination rights and any other termination rights set forth in this
        Agreement, (a) securCom can terminate this Agreement immediately upon
        written notice to Customer if Customer breaches Section 7(b) and (b)
        either party can terminate this Agreement upon written notice to the
        other party if (i) such other party breaches this Agreement (other than
        breaches of Section 7(b)) and fails to cure such breach within thirty
        (30) days of receipt of written notice thereof or (ii) such other party
        (A) becomes insolvent, admits in writing its inability to pay debts as
        they mature or makes an assignment for the benefit of creditors; (B)
        becomes subject to control of a trustee, receiver or similar authority
        or any bankruptcy or insolvency proceeding; or (C) an equivalent or
        similar event or proceeding occurs in respect 9 of the Customer in any
        jurisdiction (in each case of (A), (B) and (C), which, if initiated
        involuntarily, is not dismissed within forty-five (45) days of its
        institution).
      </li>
      <li>
        <strong> EFFECTS OF TERMINATION.</strong> THE TERMINATION OF THE
        SECURCOM SERVICES WILL CAUSE SUCH SECURCOM SERVICES TO CEASE FUNCTIONING
        AND RESULT IN CUSTOMER NOT BEING ABLE TO ACCESS ANY USER DATA. FOLLOWING
        TERMINATION OF THIS AGREEMENT, SECURCOM WILL DESTROY ALL USER DATA
        (WITHOUT PREJUDICE TO SECTION 9(d) BELOW). The termination of this
        Agreement for any reason will not affect: (i) the obligations of
        Customer and securCom to account for and pay to one another any amounts
        for which they are obligated by virtue of transactions or events which
        occurred prior to the effective date of termination; or (ii) any other
        obligation or liability which either Customer or securCom has to the
        other under this Agreement and which, by its nature, would reasonably be
        expected to survive termination. The following Sections will survive any
        expiration or termination of this Agreement: 1, 3(b), 3(c), 4, 5, 6(c),
        9(c), 9(d), 11, 12, 13, 14, 15 and 19.
      </li>
      <li>
        <strong>USER DATA POST EXPIRATION OR TERMINATION.</strong> Before
        expiration or termination of the applicable Service Term, securCom
        recommends that Customer ensures it places a copy of its User Data in a
        place that can be accessed without the securCom Services. Provided that
        Customer is in compliance with all of the terms and conditions of this
        Agreement, securCom will extend Customer’s ability to access the
        securCom Services for fifteen (15) calendar days following the end of
        the Service Term if Customer notifies securCom via email (directed to
        <a href="mailto:support@securcom.me">support@securcom.me</a> ) prior to
        the end of the Service Term. In such event, (i) securCom will enable
        Customer’s access to the securCom Services for a maximum of fifteen (15)
        calendar days following the end of the Service Term (at no additional
        cost to Customer) for the sole purpose of enabling Customer’s retrieval
        of its User Data, and (ii) Customer’s use of the securCom Services
        during such complimentary period is subject to the terms and conditions
        of this Agreement. securCom is not responsible for the availability or
        accessibility of User Data following the later of (A) the expiration of
        the complimentary period should Customer make such request or (B) the
        end of the Service Term.
      </li>
    </ol>
    <br />
    <h4>10. SUSPENSION.</h4>
    <ol type="a">
      <li>
        <strong>GENERALLY.</strong> securCom may suspend your use of the
        securCom Services if securCom reasonably determines: (i) Customer, or
        Customer’s use of the securCom Services, is in breach of this Agreement;
        (ii) Customer fails to address securCom’s request to take action as
        specified in Section 7(b); (iii) Customer’s use of the securCom Services
        poses a security risk to the securCom Services or other users of the
        securCom Services; (iv) suspension is warranted pursuant to securCom’s
        receipt of a subpoena, court order, or a request by a law enforcement
        agency; or (v) as otherwise expressly set forth in this Agreement.
        securCom will give you notice before securCom suspends, subject to
        Applicable Law, and unless securCom reasonably determines that providing
        the notice presents risk of harm to the securCom Services or any person
        or property. securCom is entitled to obtain injunctive relief if
        Customer’s use of the securCom Services is in violation of any
        restrictions set forth in this Agreement.
      </li>
      <li>
        <strong>EFFECT OF SUSPENSION.</strong> You will remain responsible for
        all fees incurred before or during any suspension. You will not be
        entitled to any service credits under any applicable Service Level
        Agreement that you might have otherwise accrued during the period of
        suspension.
      </li>
    </ol>
    <br />
    <h4>11. ALLOCATION OF RISK.</h4>
    <p>
      Customer acknowledges and agrees that securCom has set its prices and
      entered into this Agreement and permitted Customer’s access to the
      securCom Services in reliance upon the 10 disclaimers of warranty and the
      limitations of liability in this Agreement, that the same reflect an
      allocation of risk between securCom and Customer (including the risk that
      a contract remedy may fail of its essential purpose and cause
      consequential loss), and that the same form an essential basis of the
      bargain between securCom and Customer. If Customer is subject to
      Applicable Laws that prohibit Customer from indemnifying securCom as set
      forth herein or prohibit Customer from entering into the risk allocation
      arrangement set forth herein, then the terms of such provisions of this
      Agreement will apply to Customer only to the fullest extent permitted by
      Applicable Law, it being understood that Customer and securCom each wish
      to enforce the provisions of this Agreement to the maximum extent
      permitted by Applicable Law.
    </p>
    <br />
    <h4>12. DISCLAIMER.</h4>
    <p>
      EXCEPT AS EXPRESSLY PROVIDED HEREIN AND TO THE FULLEST EXTENT PERMITTED
      UNDER APPLICABLE LAW, SECURCOM SERVICES ARE PROVIDED ON AN "AS IS" BASIS
      WITHOUT ANY WARRANTY, TERMS OR CONDITIONS WHATSOEVER. ALL OTHER
      WARRANTIES, TERMS OR CONDITIONS, EXPRESS OR IMPLIED, INCLUDING ANY
      WARRANTIES OF NON-INFRINGEMENT, TITLE, SATISFACTORY QUALITY,
      MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE, ARE SPECIFICALLY
      EXCLUDED AND DISCLAIMED BY SECURCOM AND ITS LICENSORS, TO THE EXTENT
      PERMITTED BY APPLICABLE LAW. NEITHER SECURCOM NOR ITS LICENSORS WARRANT
      THAT THE SECURCOM SERVICES WILL MEET CUSTOMER’S REQUIREMENTS, THAT THE
      SECURCOM SERVICES WILL BE COMPATIBLE WITH CUSTOMER’S DEVICES, OR THAT THE
      SECURCOM SERVICES WILL BE UNINTERRUPTED OR ERROR FREE. EXCEPT AS EXPRESSLY
      PROVIDED HEREIN AND SAVE TO THE EXTENT SUCH ALLOCATION OF RISK IS NOT
      PERMITTED BY APPLICABLE LAW, THE ENTIRE RISK AS TO THE QUALITY AND
      PERFORMANCE OF SECURCOM SERVICES IS WITH CUSTOMER. IN NO EVENT WILL
      SECURCOM OR ITS LICENSORS BE LIABLE FOR ANY LOSS, LIABILITY, DAMAGES OR
      CLAIMS RELATED TO ANY REGULATORY OBLIGATIONS CUSTOMER MAY HAVE RELATED TO
      ITS USER DATA.
    </p>
    <br />
    <h4>13. LIMITATION OF securCom LIABILITY.</h4>
    <ol type="a">
      <li>
        TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
        SECURCOM BE LIABLE TO CUSTOMER FOR ANY LOST PROFITS, LOST SAVINGS, LOST
        OR CORRUPTED USER DATA, UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
        TRANSMISSIONS OF USER DATA, LOST REVENUE, BUSINESS INTERRUPTION, OR LOSS
        OF CAPITAL (IN EACH CASE, WHETHER DIRECT OR INDIRECT) OR FOR ANY
        SPECIAL, CONSEQUENTIAL, INDIRECT OR INCIDENTAL DAMAGES ARISING OUT OF OR
        RELATING TO THIS AGREEMENT OR ANY SECURCOM SERVICES FURNISHED OR TO BE
        FURNISHED UNDER THIS AGREEMENT OR THE USE THEREOF, EVEN IF SECURCOM HAS
        BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE. EXCEPT FOR
        LIABILITY ARISING OUT OF SECURCOM’S INDEMNIFICATION OBLIGATIONS UNDER
        SECTION 14, THE AGGREGATE LIABILITY OF SECURCOM IN CONNECTION WITH ANY
        AND ALL CLAIMS HOWSOEVER ARISING OUT OF OR RELATING TO THIS AGREEMENT OR
        ANY SECURCOM SERVICES FURNISHED OR TO BE FURNISHED UNDER THIS AGREEMENT
        WILL IN ANY EVENT BE ABSOLUTELY LIMITED TO: (A) THE AMOUNT PAID TO
        SECURCOM FOR THE SECURCOM SERVICES UNDER THIS AGREEMENT DURING THE
        TWELVE (12) MONTHS IMMEDIATELY PROCEEDING THE DATE THE DAMAGED PARTY
        NOTIFIES THE OTHER PARTY IN WRITING OF THE CLAIM FOR DAMAGES; OR (B) IF
        GREATER, SUCH AMOUNT AS IS THE MINIMUM AMOUNT FOR WHICH SECURCOM WOULD
        BE LIABLE UNDER APPLICABLE LAW.
      </li>
      <li>
        IF CUSTOMER IS FROM GERMANY, NONE OF THE PROVISIONS OF THIS AGREEMENT
        SHALL EXCLUDE OR LIMIT ANY LIABILITY FOR DAMAGE FROM INJURY TO LIFE,
        BODY OR HEALTH OR 11 ANY LIABILITY FOR ANY DAMAGE ARISING FROM GROSSLY
        NEGLIGENT OR INTENTIONAL BREACH OF DUTY OR OTHER CONDUCT.
      </li>
      <li>
        FURTHER LIMITATIONS. securCom’s licensors and service providers will
        have no liability of any kind under this Agreement. Customer may not
        bring a claim under this Agreement more than eighteen (18) months after
        the cause of action arises. For German customers only, Customer may not
        bring a claim under this Agreement more than eighteen (18) months after
        the cause of action arises and the Customer obtains knowledge of the
        circumstances giving rise to the claim and of the identity of the
        obligor or would have obtained such knowledge if he had not shown gross
        negligence.
      </li>
    </ol>
    <br />
    <h4>14. COPYRIGHT, PATENT, TRADE SECRET, AND TRADEMARK INDEMNITY.</h4>
    <ol type="a">
      <li>
        <strong>INDEMNITY FOR SECURCOM SERVICES.</strong> Subject to the
        remainder of this Section 14, securCom will defend Customer against an
        Infringement Claim and indemnify Customer from the resulting costs and
        damages finally awarded against Customer to that third party by a court
        of competent jurisdiction or agreed to in settlement; provided that
        Customer: (i) promptly provides securCom with notice of any Infringement
        Claim; (ii) grants securCom sole control over the claim’s defense and
        settlement, and any related action challenging the validity of the
        allegedly infringed patent, trademark or copyright; and (iii) reasonably
        cooperates in response to securCom’s requests for assistance. Customer
        may not settle or compromise any Infringement Claim without securCom’s
        prior written consent. Notwithstanding the foregoing, securCom will have
        no obligation under this Section or otherwise with respect to any claim
        or award based on: (A) a combination of the securCom Services with
        non-securCom data, products, business processes or content, including
        User Data; (B) use of the securCom Services for a purpose or in a manner
        not specified in this Agreement or the securCom Services Descriptions,
        or otherwise in a manner for which the securCom Services were not
        designed; (C) any modification of the securCom Services made without
        securCom’s express written approval; or (D) any Evaluation Service. This
        Section 14(a) states your exclusive remedy for any Infringement Claims
        save that where the Customer is a consumer based in the EU, nothing in
        this Section 14(a) will limit or exclude the Customer’s statutory rights
        except as permitted by Applicable Law.
      </li>
      <li>
        <strong> INDEMNITY FOR THIRD PARTY PRODUCTS.</strong> To the extent
        required or allowed, securCom will pass through to Customer any
        indemnities related to Third Party Products, if any. Notwithstanding the
        foregoing, Customer acknowledges that securCom is not responsible for
        the fulfillment of any Third Party Product indemnities or for problems
        attributable to use of Third Party Products.
      </li>
      <li>
        <strong> REMEDIES.</strong> If any component of the securCom Services
        becomes, or in securCom’s opinion is likely to become, the subject of an
        Infringement Claim, securCom will at securCom’s option and expense: (i)
        procure the rights necessary for Customer to keep using such component;
        (ii) modify or replace such component to make it non-infringing; or
        (iii) terminate this Agreement and refund any pre-paid fees for any
        securCom Services pro-rated for its remaining term.
      </li>
    </ol>
    <br />
    <h4>15. INDEMNITY BY CUSTOMER.</h4>
    <p>
      Customer will, to the fullest extent permitted by Applicable Law,
      indemnify securCom and its officers, directors, shareholders, employees
      and agents and their respective successors and assigns (collectively, the
      “securCom Indemnified Parties”) against and hold the securCom Indemnified
      Parties harmless from any and all claims, liabilities, damages, costs and
      expenses, including reasonable attorneys' fees in connection with
      investigating, defending, or settling any claim relating 12 to or arising
      out of any acts or omissions on the part of Customer which give rise to
      claims against securCom Indemnified Parties by third parties (unaffiliated
      with securCom), provided any final settlement will require securCom’s
      consent (which will not be unreasonably withheld) if the final settlement
      or compromise does not provide for the unconditional and full release of
      the securCom Indemnified Parties or if the final settlement or compromise
      requires the specific performance of securCom Indemnified Parties. In all
      events, securCom will have the right to participate in the defense of any
      such suit or proceeding through counsel of its own choosing at securCom’s
      own cost. Customer will also indemnify and hold harmless the securCom
      Indemnified Parties for any costs and expenses, including reasonable
      attorneys’ fees, incurred in responding to any subpoena, search warrant,
      or court order requiring production of information or documents related to
      Customer.
    </p>
    <br />
    <h4>16. U.S. GOVERNMENT PURCHASES.</h4>
    <p>
      securCom provides the securCom Services, including related software and
      technology, as “Commercial Items,” as that term has been defined at 48
      C.F.R. §2.101, consisting of “Commercial Computer Software” and
      “Commercial Computer Software Documentation,” as such terms have been used
      in 48 C.F.R. §12.212 or 48 C.F.R. §227.7202, as applicable. Consistent
      with 48 C.F.R. §12.212 or 48 C.F.R. §227.7202-1 through 227.7202-4, as
      applicable, the securCom Services are provided to U.S. government
      customers (i) only as Commercial Items; and (ii) with only those rights as
      provided under the terms and conditions of this Agreement. If a government
      agency has a need for rights not conveyed under these terms, it must
      negotiate with securCom to determine if there are acceptable terms for
      transferring such rights, and a mutually acceptable written addendum
      specifically conveying such rights must be included in any applicable
      contract or agreement.
    </p>
    <br />
    <h4>17. ANTICORRUPTION LAWS.</h4>
    <p>
      Customer and securCom each acknowledges that it is familiar with the U.S.
      Foreign Corrupt Practices Act (the “FCPA”) and agrees to comply with its
      terms as well as any provisions of local law related thereto.
      Specifically, Customer and securCom each are familiar with the provisions
      of the FCPA prohibiting the payment or giving of anything of value,
      including but not limited to payments, gifts, travel, entertainment and
      meals, either directly or indirectly, to an official of a foreign
      government or political party for the purpose of influencing an act or
      decision in his or her official capacity or inducing the official to use
      his or her party's influence with that government, to obtain or retain
      business involving the offering. Customer and securCom each agrees to not
      violate or knowingly let anyone violate the FCPA and that no payment it
      makes will constitute a bribe, influence payment, kickback, rebate, or
      other payment that violates the FCPA or any other applicable
      anti-corruption or anti-bribery law.
    </p>
    <br />
    <h4>18. YOUR OBLIGATIONS.</h4>
    <p>
      Customer represents and warrants that (a) Customer will use the securCom
      Services only for lawful purposes, and will comply with all Applicable
      Laws and (b) Customer’s access to and collection, use, relocation,
      storage, disclosure and disposition of User Data will comply with all
      Applicable laws, including without limitation, all privacy and data
      security laws.
    </p>
    <br />
    <h4>19. GENERAL PROVISIONS.</h4>
    <ol type="a">
      <li>
        <strong>PUBLICITY.</strong> securCom must not use a Customer logo or
        trademark in any way without Customer’s prior written approval;
        provided, however, that securCom may use Customer’s name and logo on
        securCom’s website and marketing materials solely to identify Customer
        as a securCom customer (without revealing any details about the parties’
        relationship or this 13 Agreement). Customer will reasonably consider
        serving as a reference for securCom. If Customer has any issues with any
        use by securCom of Customer’s name or logo, securCom and Customer will
        cooperate reasonably to resolve the issue promptly to Customer’s
        satisfaction.
      </li>
      <li>
        <strong> SEVERABILITY.</strong> Every provision of this Agreement will
        be construed, to the extent possible, so as to be valid and enforceable.
        If any provision of this Agreement so construed is held by a court of
        competent jurisdiction to be invalid, illegal or otherwise
        unenforceable, such provision will be deemed severed from this
        AGREEMENT, and all other provisions will remain in full force and
        effect.
      </li>
      <li>
        <strong>GOVERNING LAW.</strong> Except as otherwise expressly provided
        herein, this Agreement is governed by the laws of the Slovak Republic.
      </li>
      <li>
        <strong>DISPUTE RESOLUTION.</strong> Subject to Section 19(e) below, the
        parties agree that any dispute, claim, or controversy arising out of or
        relating to this Agreement or the breach, termination, enforcement,
        interpretation, or validity thereof, including the determination of the
        scope or applicability of this Agreement to arbitrate, shall be
        determined solely and exclusively by binding arbitration before a single
        arbitrator (the “Arbitrator”). The parties also agree that the
        arbitration shall be conducted in Bratislava, Slovakia, unless otherwise
        agreed to in writing by the parties. The parties further agree that this
        Agreement does not permit a class arbitration, even if the procedures or
        rules or other dispute-resoluton organization or body would otherwise
        permit it. YOU AGREE THAT, BY ENTERING INTO THIS AGREEMENT, YOU AND
        SECURCOM ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY AND THE RIGHT TO
        PARTICIPATE IN A CLASS ACTION. NO CLASS ACTION OR REPRESENTATIVE OR
        PRIVATE ATTORNEY GENERAL THEORIES OF LIABILITY OR PRAYERS FOR RELIEF MAY
        BE MAINTAINED IN ANY ARBITRATION OR OTHER PROCEEDING UNDER THIS
        AGREEMENT. Either party may initiate arbitration by filing and serving a
        written demand for arbitration, which must be served on the other party
        by overnight mail with delivery confirmation to the addresses set forth
        in this Agreement. The parties covenant and agree that the arbitration
        hearing shall commence within 180 days of the date on which a written
        demand for arbitration is filed by any party hereto (the “Filing Date”).
        The Arbitrator’s decision and award (the “Award”) shall be made and
        delivered within 240 days of the Filing Date, and shall set forth a
        reasoned basis for the Award. The Arbitrator shall not have the power to
        award damages in excess of traditional (i.e., benefit of the bargain)
        compensatory damages in contract and may not award special, liquidated,
        multiple, punitive, or other damages, and each party hereby irrevocably
        waives any claim to such damages. In connection with the arbitration
        proceeding, the Arbitrator shall have the power to allow each party to
        (i) propound up to five (5) requests for the production of documents,
        including subparts, and (ii) conduct five (5) depositions of witnesses.
        Interrogatories may not be propounded by any party, and all discovery
        must be completed within 60 days after the selection of the arbitration,
        and no later than 120 days after the Filing Date. The Arbitrator shall
        also have the power to issue a subpoena for documents or information to
        any third-party witness within his or her jurisdiction. The parties
        agree that the entirety of the arbitration proceedings, including all
        documents and information produced by any party or non-party, all
        deposition testimony, and all pleadings, motions, or correspondence
        exchanged in connection with the arbitration proceeding, shall be kept
        confidential. Each Party hereby irrevocably agrees and submits to
        exclusive jurisdiction and venue in the district courts in Bratislava,
        Slovakia, for entry of judgment on the Award or for relief in aid of
        arbitration; except, however, if the district courts in Bratislava,
        Slovakia, decline to exercise jurisdiction, each Party agrees and
        submits to exclusive jurisdiction and venue in the state courts of
        Slovak Republic for the entry of 14 judgment on the Award. This clause
        shall not preclude parties from seeking: (i) provisional remedies in aid
        of arbitration from a court of appropriate jurisdiction; (ii) a
        temporary restraining order from a court of appropriate jurisdiction
        related to the purposes of this Agreement; or (iii) relief from a small
        claims court for disputes or claims within the scope of such court’s
        jurisdiction. If the courts in Your country will not permit You to agree
        to the arbitration, jurisdiction and venue of the courts described
        above, then Your local jurisdiction and venue will apply to any dispute
        or difference arising out of or in connection with the Agreement. For EU
        consumers only - The European Commission’s online dispute resolution
        platform can be found here
        <a href="http://ec.europa.eu/odr">http://ec.europa.eu/odr</a>. securCom
        is not obliged to submit to any alternative dispute resolution
        procedure, other than the arbitration proceeding.
      </li>
      <li>
        <strong> OTHER APPLICABLE LAW.</strong> If You are otherwise subject to
        laws that prohibit Customer from agreeing to the foregoing governing law
        and/or venue provisions, then (i) the terms of such provisions of this
        Agreement will be deemed to be modified to reflect the governing law
        and/or venue required by Applicable Law; and (ii) Customer must, within
        thirty (30) days of the commencement of its Service Term, notify
        securCom (directed to
        <a href="legal@securcom.me">legal@securcom.me</a> ) to identify the
        Applicable Laws that apply to Customer and the resulting modifications
        to the governing law and/or venue provisions of this Agreement, without
        prejudice to the statutory rights of consumers based in the EU.
      </li>
      <li>
        <strong>ENTIRE AGREEMENT.</strong> This Agreement sets forth the entire
        Agreement and understanding between securCom and Customer regarding the
        subject matter hereof and supersedes any previous or contemporaneous
        communications, representations, proposals, commitments, understandings,
        negotiations, discussions, understandings, or Agreements (including
        non-disclosure or confidentiality Agreements), whether oral of written,
        regarding the same subject matter. In the event of any conflict between
        these Terms of Service and an Order Form, the terms and conditions set
        forth in these Terms of Service will govern unless expressly amended in
        such Order Form.
      </li>
      <li>
        <strong>WAIVER.</strong> The failure by securCom at any time to enforce
        any of the provisions of this Agreement or any right or remedy available
        hereunder or at law or in equity, or to exercise any option herein
        provided, will not constitute a waiver of such provision, right, remedy
        or option or in any way affect the validity of this Agreement. The
        waiver of any default by securCom will not be deemed a continuing
        waiver, but will apply solely to the instance to which such waiver is
        directed.
      </li>
      <li>
        <strong>HEADINGS.</strong> The section headings appearing in this
        Agreement are inserted only as a matter of convenience and in no way
        define, limit, construe or describe the scope or extent of such section
        or in any way affect such section.
      </li>
      <li>
        <strong>NO JOINT VENTURE.</strong> This Agreement will not be construed
        as creating any partnership, joint venture or agency relationship
        between securCom and Customer.
      </li>
      <li>
        <strong>ASSIGNMENT.</strong> securCom may freely assign, transfer and/or
        delegate its rights and obligations under this Agreement but Customer
        may not assign, transfer and/or delegate its rights and obligations
        under this Agreement without securCom’s prior written consent (not to be
        unreasonably withheld). Any attempted assignment or transfer in
        violation of this Section will be void. Subject to these limits, this
        Agreement will be binding upon and inure to the benefit of the parties
        and their respective successors and permitted assigns.
      </li>
      <li>
        <strong>NO THIRD PARTY BENEFICIARIES.</strong> Other than as expressly
        provided in this Agreement, no third-party beneficiaries are intended or
        will be construed as created by this Agreement.
      </li>
      <li>
        <strong>NOTICES.</strong> Any notice delivered by securCom to Customer
        under this Agreement will be delivered by email to the email address
        associated with Customer’s account or by posting at securCom Manager,
        except as otherwise set forth in this Agreement. Customer will direct 15
        legal notices or other correspondence, including any complaints, under
        this Agreement (including under any Additional Terms) to securCom
        s.r.o., Suché mýto 1, 1st floor, 811 03 Bratislava, Slovak Republic,
        Attention: Legal Department, or by email directed at
        <a href="legal@securcom.me">legal@securcom.me</a>.
      </li>
      <li>
        <strong>FORCE MAJEURE.</strong> securCom will not be liable for any
        delay or failure to perform any obligations under this Agreement due to
        any cause beyond securCom’s reasonable control, including acts of God,
        labor disputes or other industrial disturbances, systemic electrical,
        telecommunications or other utility failures, earthquakes, storms or
        other elements of nature, blockages, embargoes, riots, pandemic, acts or
        orders of government, acts of terrorism or war. If such a force majeure
        event occurs and continues for a period of more than thirty (30) days,
        either party may terminate this Agreement upon written notice to the
        other party.
      </li>
      <li>
        <strong>EXPORT COMPLIANCE.</strong> Customer may not use or otherwise
        export or re-export the securCom Services or any related software or
        technology except as authorized by law of Slovak Republic and European
        Union, and the Applicable Laws of the jurisdiction in which the securCom
        Services were obtained. In particular, but without limitation, the
        securCom Services may not be exported or re-exported (i) into any U.S.
        embargoed country or region, or (ii) to anyone on the U.S. Treasury
        Department's list of Specially Designated Nationals or the U.S.
        Department of Commerce Denied Person’s List, Entity List or Unverified
        List. By using the securCom Services, Customer represen1.1. For the
        purposes of this Addendum:, and securCom makes no representations or
        warranties regarding the suitability of the securCom Services for
        Customer’s compliance with the EAR and/or ITAR.
      </li>
    </ol>
    <br />
    <h4>20. ORDER OF PRECEDENCE.</h4>
    <p>
      In the event of a conflict between or among any provision set forth in an
      Order Form, the Terms of Service, or any of the Additional Terms, the
      following order of precedence shall apply, from most controlling to least
      controlling (provided, however, that the existence of a provision in a
      document and the absence of such provision in another document shall not
      constitute a conflict for the purposes of this provision): (a) the Order
      Form; (b) the Terms of Service; (c) Privacy Policy; (d) Data Privacy
      Addendum; (e) Third Party Terms; (f) Service Descriptions; (g) Service
      Level Agreement; and (h) Support Policies.
    </p>
    <br />
    <h4>21. QUESTIONS.</h4>
    <p>
      Any questions regarding this Agreement should be directed to securCom
      using any of the contact methods located at
      <a href="https://www.securcom.me/contact"
        >https://www.securcom.me/contact</a
      >.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
a {
  color: #409875;
}

p {
  line-height: 1.4rem;
}

li {
  margin-top: 5px;
  margin-left: 30px;
}

.main {
  height: 91%;
  margin-top: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
}

img {
  width: 100%;
  max-width: 230px;
  margin-left: -5px;
}

@media only screen and (max-width: 576px) {
  li {
    margin-left: 0px;
  }
}
</style>